module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-theme-ghost-tailwind/gatsby-browser.js'),
      options: {"plugins":[],"ghostConfig":{"development":{"apiUrl":"https://isaac-blog.herokuapp.com","contentApiKey":"807eb6dbffb9e2f146bea8a9d1"},"production":{"apiUrl":"https://isaac-blog.herokuapp.com","contentApiKey":"807eb6dbffb9e2f146bea8a9d1"}},"siteConfig":{"siteUrl":"https://ialmanza.com","infiniteScroll":true,"postsPerPage":4,"siteTitleMeta":"Isaac Almanza","siteDescriptionMeta":"This is the home of Isaac Almanza. Learning about startups, personal growth, and how you can make better decisions.","shareImageWidth":1000,"shareImageHeight":523,"shortTitle":"Isaac","siteIcon":"favicon.png","backgroundColor":"#e9e9e9","themeColor":"#15171A","verbose":false,"severity":"info"},"mediaConfig":null,"routes":{"basePath":"/blog"}},
    },{
      plugin: require('../node_modules/gatsby-theme-ghost-dark-mode/gatsby-browser.js'),
      options: {"plugins":[],"defaultModeDark":false,"overrideOS":false},
    },{
      plugin: require('../node_modules/gatsby-theme-ghost-toc/gatsby-browser.js'),
      options: {"plugins":[],"maxDepth":3},
    },{
      plugin: require('../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"XVXxeB7oNTUmLN4alcRGd1t4lbiA9t4G","devKey":"","trackPage":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Isaac Almanza","short_name":"ialmanza","start_url":"/","background_color":"#f7f0eb","theme_color":"#a2466c","display":"standalone","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":null},
    }]
